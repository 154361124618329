import req from '../utils/request.js'
const path = '/api/mall'

/* 获取订单列表 */
export function getBlocOrderList(params) {
  return req.post(`${path}/manage/order/list`, params)
}

/* 修改订单列表 */
export function updateBlocOrderList(params) {
  return req.post(`${path}/manage/order/update`, params)
}

/* 取消订单 */
export function cancelBlocOrderList(params) {
  return req.fromPost(`${path}/manage/order/cancel`, params)
}

/* 确认收货 */
export function sureBlocOrderList(params) {
  return req.fromPost(`${path}/manage/order/confirm`, params)
}

/* 确认退款 */
export function sureWaitingRefund(params) {
  return req.fromPost(`${path}/manage/order/refund`, params)
}
