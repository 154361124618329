<template>
  <div style="height: 95%">
    <div class="content">
      <a-table
        bordered
        :data-source="stockData"
        :pagination="false"
        rowKey="goodsId"
        :scroll="{ x: 1200, y: 500 }"
      >
        <a-table-column
          key="categoryName"
          title="分类名称"
          data-index="categoryName"
        />
        <a-table-column key="name" title="商品名称" data-index="name" />
        <a-table-column
          key="specification"
          title="商品规格"
          data-index="specification"
        />
        <a-table-column key="totalNum" title="库存数量" data-index="totalNum" />
        <a-table-column
          key="incomingNum"
          title="入库总数"
          data-index="incomingNum"
        />
        <a-table-column
          key="outboundNum"
          title="出库总数"
          data-index="outboundNum"
        />
        <a-table-column key="action" title="操作">
          <template slot-scope="record">
            <span>
              <a @click="outOrInStorage(record, 'out')">出库</a>
              <a-divider type="vertical" />
              <a @click="outOrInStorage(record, 'in')">入库</a>
              <a-divider type="vertical" />
              <a @click="wareLog(record)">日志</a>
            </span>
          </template>
        </a-table-column>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        :page-size-options="pageSizeOptions"
        :showSizeChanger="true"
        show-quick-jumper
        :current="params.page"
        :total="total"
        :show-total="total => `共 ${total} 条数据`"
        :pageSize="params.limit"
        @change="onChangePage"
        @showSizeChange="onShowSizeChange"
      />
      <!-- 出入库 -->
      <a-modal
        v-model="storage"
        :title="dialogTitle"
        :okText="okText"
        cancelText="取消"
        @ok="storageOnSubmit"
      >
        <a-form-model :model="storageObj" ref="ruleForm" :rules="storageRules">
          <div class="form_model">
            <div class="dec">
              <a-form-model-item label="商品类别">
                {{ storageObj.categoryName }}
              </a-form-model-item>
              <a-form-model-item label="商城名称">
                {{ storageObj.name }}
              </a-form-model-item>
            </div>
            <div class="dec">
              <a-form-model-item label="商品规格">
                {{
                  storageObj.specification === null
                    ? '--'
                    : storageObj.specification
                }}
              </a-form-model-item>
              <a-form-model-item label="库存数量">
                {{ storageObj.totalNum }}
              </a-form-model-item>
            </div>
            <a-form-model-item
              label="入库数量"
              prop="in"
              v-if="dialogTitle === '入库'"
            >
              <a-input
                v-model="storageObj.in"
                placeholder="必填"
                type="number"
              />
            </a-form-model-item>
            <a-form-model-item
              label="出库数量"
              prop="out"
              v-if="dialogTitle === '出库'"
            >
              <a-input
                v-model="storageObj.out"
                placeholder="必填"
                type="number"
              />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="explain">
              <a-input
                v-model="storageObj.explain"
                type="textarea"
                ref="descInputValue"
                @keyup="descpNum"
              />
              <span class="descNum">{{ descNum }}/50</span>
            </a-form-model-item>
          </div>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { getBlocGoodsList, updateBlocStockLList } from '../../../api/goods.js'
import local from '../../../utils/local.js'
export default {
  inject: ['reload'],
  props: {
    search: {
      type: Object
    }
  },
  data() {
    const self = this
    return {
      stockData: [],
      // 新增
      insertObj: {},
      // 出入库
      storageObj: {},
      rules: {
        name: [
          {
            required: true,
            message: '请填写仓库名称',
            trigger: 'blur'
          }
        ],
        name1: [
          {
            required: true,
            message: '请选择仓库类型',
            trigger: 'change'
          }
        ],
        name2: [
          {
            required: true,
            message: '请选择酒店',
            trigger: 'change'
          }
        ],
        explain: [
          {
            min: 0,
            max: 20,
            message: '长度限制20个字符',
            trigger: 'blur'
          }
        ]
      },
      storageRules: {
        out: [
          {
            required: true,
            message: '请填写出库数量',
            trigger: 'blur'
          },
          {
            validator: self.validateAcquaintance,
            trigger: 'blur'
          },
          {
            pattern: new RegExp(/^[1-9]\d*$/, 'g'),
            message: '请输入正整数',
            trigger: 'blur'
          }
        ],
        in: [
          {
            required: true,
            message: '请填写入库数量',
            trigger: 'blur'
          },
          {
            pattern: new RegExp(/^[1-9]\d*$/, 'g'),
            message: '请输入正整数',
            trigger: 'blur'
          }
        ],
        explain: [
          {
            message: '备注长度在0-20个字符',
            min: 0,
            max: 20,
            trigger: 'blur'
          }
        ]
      },
      // model标题
      dialogTitle: '',
      // model确认文字
      okText: '',
      // 新增model
      visible: false,
      // 出入库model
      storage: false,
      // 文本域动态长度
      descNum: 0,
      // 库存最大数量
      maxNum: 20,
      // 分页参数
      pageSizeOptions: ['5', '10', '20', '30', '50'],
      page: 1,
      limit: 10,
      total: 20,
      params: {
        page: 1,
        limit: 20,
        categoryId: '',
        goodsName: '',
        goodsStatus: '',
        parentId: ''
      }
    }
  },
  created() {
    this.params.parentId = this.search.parentId
    this.params.categoryId = this.search.categoryId
    this.params.goodsName = this.search.goodsName
    this.params.goodsStatus = this.search.goodsStatus
    this.getGoodsList()
  },
  methods: {
    // 获取商品列表
    async getGoodsList() {
      const data = await getBlocGoodsList(this.params)
      this.total = data.data.total
      this.stockData = data.data.records
    },
    async getList() {
      const data = await getBlocGoodsList(this.params)
      this.stockData = data.data.records === null ? [] : data.data.records
    },
    // 分页
    onChangePage(pageNumber) {
      this.params.page = pageNumber
      this.getGoodsList(
      )
    },
    onShowSizeChange(pageNumber, limit) {
      this.params.limit = limit
      this.params.page = 1
      this.getGoodsList()
    },
    // 出入库
    outOrInStorage(r, d) {
      this.storage = true
      if (d === 'out') {
        this.dialogTitle = '出库'
        this.okText = '确认出库'
      } else if (d === 'in') {
        this.dialogTitle = '入库'
        this.okText = '确认入库'
      }
      this.storageObj = JSON.parse(JSON.stringify(r))
      // this.storageObj.explain = JSON.parse(JSON.stringify(r)).explain.toString()
    },
    validateAcquaintance(rule, value, callback) {
      if (value > this.storageObj.totalNum) {
        callback(new Error('出库数量不能大于库存数量!'))
      } else {
        callback()
      }
    },
    // 备注文字数量计算
    descpNum() {
      this.descNum = this.$refs.descInputValue.value
        ? this.$refs.descInputValue.value.length
        : 0
    },
    wareLog(record) {
      this.$router.push({
        path: '/warehouse/log',
        query: { goodsId: record.goodsId }
      })
    },
    storageOnSubmit() {
      // const _this = this
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const changeMap = {}
          const number =
            this.dialogTitle === '入库'
              ? this.storageObj.in
              : this.storageObj.out
          changeMap[this.storageObj.goodsId] = number
          let operationTypeString = ''
          const explain = this.storageObj.explain
          this.dialogTitle === '入库'
            ? (operationTypeString = 'PUT_IN')
            : (operationTypeString = 'OUT_BOUND')
          const operationUser = local.get('userName')
          const data = await updateBlocStockLList({
            changeMap,
            operationTypeString,
            operationUser,
            explain
          })
          if (data.code === 0) {
            this.$message.success(data.message)
          } else {
            this.$message.error(data.message)
          }
          this.page = 1
          this.getList()
          this.storage = false
        } else {
          return false
        }
      })
    }
  },
  computed: {
    getSearch() {
      return this.search
    }
  },
  watch: {
    search: {
      deep: true,
      handler: function(newval, oldval) {
        const params = {
          mallTypeString: 'BLOC',
          limit: this.limit,
          page: this.page,
          parentId: newval.parentId,
          goodsName: newval.goodsName,
          categoryId: newval.categoryId,
          goodsStatus: newval.goodsStatus
        }
        newval.categoryId === 'all' ? delete params.categoryId : ''
        newval.goodsStatus === 'all' ? delete params.goodsStatus : ''
        newval.goodsName === '' ? delete params.goodsName : ''
        if (newval.goodsStatus === 'all' && newval.categoryId === 'all') {
          delete params.categoryId
          delete params.goodsStatus
        }
        this.params = params
        this.getList()
      }
    }
  }
}
</script>

<style lang="less" scoped>
@baseColor: #4196ff;
.content {
  background-color: #fff;
  padding-bottom: 2.4rem;
  height: 95%;
}

/deep/.ant-modal-content {
  width: 74rem;
  /deep/.ant-btn-primary {
    background-color: @baseColor;
    border-color: @baseColor;
    &:hover {
      color: #fff !important;
    }
  }
  /deep/.ant-btn:hover {
    color: @baseColor;
    border-color: @baseColor;
  }
}

.form {
  display: flex;
  flex-wrap: wrap;
  .ant-form-item {
    width: 50%;
  }
}

.form_model .ant-form-item {
  margin-bottom: 1rem !important;
}
.ant-table {
  table-layout: fixed;
}
/deep/.ant-table-tbody > tr > td {
  max-width: 200px;
  min-width: 70px;
  /*text-align: center !important;*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
}
/deep/.form_model .ant-form-item .ant-form-item-label {
  width: 100px;
}
</style>
