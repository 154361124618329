<template>
  <div class="index_wrapper">
    <div style="display: flex; justify-content: space-between">
      <div class="title">编辑订单</div>
      <a-button type="primary" @click="back"> 返回 </a-button>
    </div>
    <div class="content">
      <div class="subTil">订单信息</div>
      <a-form-model :model="orderData" ref="ruleForm" :rules="rules">
        <div class="form_model">
          <div class="dec">
            <a-form-model-item label="订单号">
              {{ orderData.orderNo }}
            </a-form-model-item>
            <a-form-model-item label="下单时间">
              {{ orderData.orderTime | formatDate }}
            </a-form-model-item>
          </div>
          <div style="margin: 2rem 0 2rem 8rem; height: 20rem">
            <a-table
              bordered
              :data-source="orderData.orderDetailList"
              :pagination="false"
              rowKey="id"
              style="
                width: 90%;
                height: 20rem;
                max-height: 20rem;
                overflow-y: auto;
              "
            >
              <a-table-column
                key="goodsName"
                title="商品名称"
                data-index="goodsName"
              />
              <a-table-column
                key="costPrice"
                title="成本价"
                data-index="costPrice"
              >
                <template slot-scope="record">
                  {{ record ? record.toFixed(2) : '--' }}
                </template>
              </a-table-column>
              <a-table-column
                key="basePrice"
                title="商品原价"
                data-index="basePrice"
              >
                <template slot-scope="record">
                  {{ record.toFixed(2) }}
                </template>
              </a-table-column>
              <a-table-column
                key="goodsPrice"
                title="商品折扣价"
                data-index="goodsPrice"
              >
                <template slot-scope="record">
                  {{ record.toFixed(2) }}
                </template>
              </a-table-column>
              <a-table-column title="商品数量">
                <template slot-scope="record">
                  <div>
                    {{ record.goodsNum }}
                  </div>
                </template>
              </a-table-column>
              <a-table-column title="状态" v-if="orderData.refundGoodsIds">
                <template slot-scope="record">
                  <div style="color: rgb(217, 86, 114)">
                    {{
                      orderData.refundGoodsIds.includes(record.goodsId)
                        ? '待退款'
                        : '--'
                    }}
                  </div>
                </template>
              </a-table-column>
            </a-table>
            <span style="width: 88%; display: flex; justify-content: flex-end"
              >商品总数：{{ totalNum }}</span
            >
          </div>
          <div class="third">
            <a-form-model-item label="订单金额">
              <a-input
                v-model="orderData.totalPrice"
                :disabled="isInsert === 1"
                type="number"
              />
            </a-form-model-item>
            <a-form-model-item
              label="退款金额"
              v-if="orderData.refundAmount !== null"
              prop="refundAmount"
            >
              <a-input v-model="orderData.refundAmount" type="number" />
            </a-form-model-item>
          </div>
          <div class="subTil" style="width: 100%">收货信息</div>
          <div class="half">
            <a-form-model-item label="姓名" prop="receiveName">
              <a-input v-model="orderData.userName" placeholder="必填" />
            </a-form-model-item>
            <a-form-model-item label="电话号码" prop="mobile">
              <a-input v-model="orderData.mobile" placeholder="必填" />
            </a-form-model-item>
            <!-- <a-form-model-item label="地区" prop="regionAddress">
              <a-input
                v-model="orderData.deliveryAddress.regionAddress"
                placeholder="必填"
              />
            </a-form-model-item>
            <a-form-model-item label="详细地址" prop="detailAddress">
              <a-input
                v-model="orderData.deliveryAddress.detailAddress"
                placeholder="必填"
              />
            </a-form-model-item> -->
            <a-form-model-item
              label="自提说明"
              style="width:100%"
              v-if="orderData.goodsTypeString === 'PHYSICAL'"
              prop="deliveryMethodString"
              ref="deliveryMethodString"
            >
              <a-radio-group
                v-model="orderData.deliveryMethodString"
                @blur="
                  () => {
                    $refs.deliveryMethodString.onFieldBlur()
                  }
                "
                @change="
                  () => {
                    $refs.deliveryMethodString.onFieldBlur()
                  }
                "
              >
                <a-radio value="PICK_UP">
                  自提
                </a-radio>
                <a-radio value="SEND_ROOM">
                  送到客房
                </a-radio>
              </a-radio-group>
              <a-input
                style="width:20%"
                v-if="orderData.deliveryMethodString === 'SEND_ROOM'"
                v-model.trim="orderData.deliveryAddress.roomNumber"
                @blur="
                  () => {
                    $refs.deliveryMethodString.onFieldBlur()
                  }
                "
                @change="
                  () => {
                    $refs.deliveryMethodString.onFieldBlur()
                  }
                "
                placeholder="客房号"
              />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark" style="width:100%">
              <a-input
                v-model="orderData.remark"
                type="textarea"
                style="width:48.5%"
                :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </a-form-model-item>
          </div>
          <a-form-model-item :wrapper-col="{ span: 14, offset: 2 }">
            <a-button style="margin-right: 1rem" @click="back"> 取消 </a-button>
            <a-button type="primary" @click="onSubmit"> 确定 </a-button>
          </a-form-model-item>
        </div>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { updateBlocOrderList } from '../../../api/order.js'
export default {
  data() {
    const validateNotNull = (rule, value, callback) => {
      if (this.orderData.receiveName === '') {
        callback(new Error('请填写姓名'))
      } else if (this.orderData.mobile === '') {
        callback(new Error('请填写手机号码'))
      } else {
        callback()
      }
    }
    const validateRange = (rule, value, callback) => {
      const refundAmount = Number(this.orderData.refundAmount)
      if (
        refundAmount < 0 ||
        refundAmount > Number(this.orderData.totalPrice)
      ) {
        callback(new Error('请输入在订单金额以内的数字'))
      } else {
        callback()
      }
    }
    const validateMethodString = (rule, value, callback) => {
      if (value === 'SEND_ROOM') {
        if (!this.orderData.deliveryAddress.roomNumber) {
          callback(new Error('请填写客房号'))
        }
        callback()
      }
      callback()
    }

    return {
      orderData: {},
      deliveryAddressInfo: {},
      rules: {
        remark: [
          {
            min: 0,
            max: 200,
            message: '长度限制200个字符',
            trigger: 'blur'
          }
        ],
        refundAmount: [
          {
            required: true,
            validator: validateRange,
            message: '请输入在订单金额以内的数字',
            trigger: 'blur'
          }
        ],
        regionAddress: [
          {
            required: true,
            validator: validateNotNull,
            message: '请填写地区！',
            trigger: 'blur'
          }
        ],
        detailAddress: [
          {
            required: true,
            validator: validateNotNull,
            message: '请填写详细地址！',
            trigger: 'blur'
          }
        ],
        receiveName: [
          {
            required: true,
            validator: validateNotNull,
            message: '请填写姓名！',
            trigger: 'blur'
          }
        ],
        mobile: [
          {
            required: true,
            validator: validateNotNull,
            message: '请填写联系电话！',
            trigger: 'blur'
          }
        ],
        deliveryMethodString: [
          {
            required: true,
            validator: validateMethodString,
            trigger: 'blur'
          }
        ]
      },
      totalPrice: 0,
      totalNum: 0,
      isInsert: '',
      orderStatusString: ''
    }
  },
  created() {
    this.orderData = this.$route.params.info
    this.orderStatusString = this.$route.params.info.orderStatusString
    this.isInsert = this.$route.params.isInsert
    let totalNum = 0
    let totalPrice = 0
    this.orderData.orderDetailList.forEach(item => {
      totalPrice += item.goodsPrice * item.goodsNum
      totalNum += item.goodsNum
    })
    this.orderData.deliveryAddress = JSON.parse(
      this.$route.params.info.deliveryAddress
    )
    this.totalPrice = totalPrice.toFixed(2)
    this.totalNum = totalNum
  },
  methods: {
    back() {
      this.$router.push({
        path: '/storeShop/order'
      })
    },
    onSubmit() {
      const orderData = { ...this.orderData }
      this.$store.state.orderStatusString = this.orderStatusString
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          orderData.deliveryAddress = JSON.stringify(orderData.deliveryAddress)
          const data = await updateBlocOrderList(orderData)
          if (data.code === 0) {
            this.$router.push({
              path: '/storeShop/order'
            })
            this.$message.success(data.message)
          }
        } else {
          return false
        }
      })
    }
  },
  // 时间戳转时间
  filters: {
    formatDate: function(value) {
      const date = new Date(value)
      const y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
    },
    orderStatus(v) {
      switch (v) {
        case 'WAITING_PAY':
          return '待支付'
        case 'WAITING_DELIVERY':
          return '待收货'
        case 'RECEIVED':
          return '已收货'
        case 'DONE':
          return '已完成'
        case 'CANCELED':
          return '已取消'
        case 'WAITING_REFUND':
          return '待退款'
        case 'REFUNDED':
          return '已退款'
        default:
          return '异常'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.index_wrapper {
  text-align: left;
  padding: 2.2rem 1.6rem 0 1.6rem;
  width: 100%;
  .title {
    font-size: 18px;
    padding-bottom: 1.8rem;
  }
  .subTil {
    font-size: 18px;
    padding-bottom: 1rem;
    color: rgb(16, 16, 16);
  }
  .content {
    background-color: #fff;
    padding: 1rem 0 2.4rem 1.2rem;
    border: 1px solid rgb(234, 234, 234);
    height: auto !important;
  }
}
.add,
.reduce {
  display: inline-block;
  width: 2.8rem;
  height: 2.8rem;
  line-height: 2.8rem;
  background-color: rgb(243, 246, 249);
  border: 0.1rem solid rgb(220, 224, 231);
  border-radius: 3px 0px 0px 3px;
  cursor: pointer;
  user-select: none;
}
.number {
  display: inline-block;
  border: 1px solid rgb(220, 224, 231);
  width: 6rem;
  height: 2.8rem;
  line-height: 2.8rem;
  border-right: 0;
  border-left: 0;
}
.reduce {
  border-radius: 0px 3px 3px 0px;
}
/deep/.ant-table-tbody > tr > td {
  padding: 4px 5px !important;
}
.third {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .ant-form-item {
    width: 48%;
    margin-right: 1rem;
  }
}
.half {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .ant-form-item {
    width: 48%;
    margin-right: 1rem;
  }
  /deep/.ant-select-selection--single {
    width: 21rem;
  }
}
.ant-form-item {
  margin-bottom: 1rem;
}
.dec {
  .ant-form-item {
    margin-bottom: 0;
  }
}

/deep/.ant-form-item-label {
  width: 84px !important;
}
</style>
