<template>
  <div class="index_wrapper">
    <div class="title">日志</div>
    <div class="content">
      <div
        style="display: flex; justify-content: flex-end; padding: 1rem 1.5rem"
      >
        <a-button type="primary" @click="back"> 返回 </a-button>
      </div>
      <a-table bordered :data-source="logList" :pagination="false" rowKey="id">
        <a-table-column
          key="operationTypeString"
          title="类别"
          data-index="operationTypeString"
        />
        <a-table-column
          key="operationTime"
          title="时间"
          data-index="operationTime"
        >
          <template slot-scope="record">
            <span>
              {{ record | formatDate }}
            </span>
          </template>
        </a-table-column>
        <a-table-column key="number" title="数量" data-index="number" />
        <a-table-column
          key="relateOrder"
          title="关联订单"
          data-index="relateOrder"
        />
        <a-table-column
          key="operationUser"
          title="操作人"
          data-index="operationUser"
        />
        <a-table-column
          key="operationExplain"
          title="备注"
          data-index="operationExplain"
        />
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { getBlocStockLogList } from '../../../api/goods'
export default {
  data() {
    this.dateFormat = 'YYYY-MM-DD'
    return {
      goodsId: '',
      limit: 10,
      page: 1,
      hotelId: '',
      value: '',
      logList: []
    }
  },
  created() {
    // moment(editData.t, dateFormat)
    this.hotelId = this.$store.state.hotelId
    this.goodsId = this.$route.query.goodsId
    this.getLog(this.goodsId, this.limit, this.page)
  },
  computed: {
    getHotelId() {
      return this.$store.state.hotelId
    }
  },
  watch: {
    getHotelId: {
      immediate: true,
      handler: function(newval, oldval) {
        this.hotelId = newval
      }
    }
  },
  methods: {
    // 获取日志
    async getLog() {
      const data = await getBlocStockLogList({
        goodsId: this.goodsId,
        limit: this.limit,
        page: this.page
      })
      this.logList = data.data.records
      for (var i = 0; i < this.logList.length; i++) {
        if (this.logList[i].operationTypeString === 'PUT_IN') {
          this.logList[i].operationTypeString = '入库'
        } else {
          this.logList[i].operationTypeString = '出库'
        }
      }
    },
    moment,
    onChange(checked) {
    },
    back() {
      this.$router.push({
        path: '/storeShop/warehouse'
      })
    }
  },
  filters: {
    formatDate: function(value) {
      const date = new Date(value)
      const y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
    }
  }
}
</script>

<style lang="less" scoped>
@baseColor: #4196ff;
.index_wrapper {
  text-align: left;
  padding: 2.2rem 1.6rem 0 1.6rem;
  width: 100%;
  .title {
    font-size: 18px;
    padding-bottom: 1.8rem;
  }
  .content {
    background-color: #fff;
    padding-bottom: 2.4rem;
    .ant-form-item {
      padding: 1.6rem 0;
      margin: 0;
    }
    .ant-select {
      width: 28rem;
      height: 32px;
      padding-right: 1rem;
    }
  }
}

/deep/.ant-modal-content {
  /deep/.ant-btn-primary {
    background-color: @baseColor;
    border-color: @baseColor;
    &:hover {
      color: #fff !important;
    }
  }
  /deep/ .ant-btn:hover {
    color: @baseColor;
    border-color: @baseColor;
  }
}
</style>
