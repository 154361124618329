<template>
  <div class="index_wrapper">
    <div class="title">商品管理</div>
    <div class="dFlex">
      <ul>
        <li
          :class="path === '/storeShop/commodity/man' ? 'active' : ''"
          @click="clickTil('/storeShop/commodity/man')"
        >
          商品列表
        </li>
        <li
          :class="path === '/storeShop/warehouse' ? 'active' : ''"
          @click="clickTil('/storeShop/warehouse')"
        >
          库存列表
        </li>
      </ul>
      <div class="search">
        <a-form-item
          label="酒店名称"
          style="display: flex; justify-content: flex-end"
        >
          <hotelSelect @selectChange="getHotelValue"></hotelSelect>
        </a-form-item>
        <a-form-item
          label="商品状态"
          style="display: flex; justify-content: flex-end"
        >
          <a-select @change="selectStatus" defaultValue="all">
            <a-select-option
              v-for="(item, i) in status"
              :key="i"
              :value="item.code"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="商品分类"
          style="display: flex; justify-content: flex-end"
        >
          <a-select @change="selectClassify" defaultValue="all">
            <a-select-option value="all">全部分类</a-select-option>
            <a-select-option
              v-for="(item, i) in categoryList"
              :key="i"
              :value="item.code"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-input-search
          placeholder="请输入商品名称"
          enter-button="搜索"
          @search="onSearch"
        />
      </div>
    </div>
    <router-view :search="searchList" />
  </div>
</template>

<script>
import { getBlocClassifyStateList } from '../../../api/goods.js'
export default {
  data() {
    return {
      path: '',
      value: '',
      hotelList: [],
      status: [
        {
          name: '全部',
          code: 'all'
        },
        {
          name: '已下架',
          code: 0
        },
        {
          name: '正常',
          code: 1
        }
      ],
      categoryList: [],
      searchList: {},
      Id: '',
      isEnter: true,
      loading: true
    }
  },
  created() {
    this.isEnter = true
    this.parentId = this.$store.state.hotelValue
    const searchList = {
      parentId: this.parentId,
      goodsStatus: '',
      categoryId: '',
      goodsName: ''
    }
    this.searchList = searchList
    this.fn()
    setTimeout(() => {
      this.getClassifyList(this.parentId)
    }, 500)
  },
  methods: {
    fn() {
      this.path = this.$route.path
    },
    async getClassifyList(temp) {
      this.isEnter = false
      this.loading = true
      const data = await getBlocClassifyStateList({
        parentId: temp
      })
      this.loading = false
      this.categoryList.splice(0, this.categoryList.length)
      data.data.forEach(item => {
        this.categoryList.push({
          code: item.id,
          name: item.categoryName
        })
      })
    },
    getHotelValue(v) {
      if (this.isEnter) {
        return
      }
      this.parentId = v
      this.searchList.parentId = v
    },
    // 选择分类
    selectClassify(v) {
      this.searchList.categoryId = v
      this.$store.state.category = this.searchList.categoryId
    },
    // 选择状态
    selectStatus(v) {
      if (v === 0) {
        this.searchList.goodsStatus = false
      } else if (v === 1) {
        this.searchList.goodsStatus = true
      } else {
        this.searchList.goodsStatus = ''
      }
      this.$store.state.status = this.searchList.goodsStatus
    },
    // 可输可选下拉框
    selectChange(v) {
      this.value = v
      this.$store.state.chooseId = this.value
      this.searchList.parentId = v
      if (v === 'all') {
        const temp = []
        this.hotelList.forEach(item => {
          item.code !== 'all' ? temp.push(item.hotelId) : ''
        })
      } else {
        const temp = v
        this.getClassifyList(v)
      }
    },
    clickTil(path) {
      this.path = path
      this.$router.push({
        path: path
      })
    },
    // 搜索
    onSearch(value) {
      this.searchList.goodsName = value
    }
  },
  computed: {
    getHotelId() {
      return this.$store.state.shopId
    }
  },
  watch: {
    getHotelId: {
      handler: function() {
        const Id = this.$store.state.shopId
        if (Id === 'all') {
          this.getClassifyList(this.parentList[0].code)
        } else {
          this.getClassifyList(Id)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
@baseColor: #4196ff;
.index_wrapper {
  text-align: left;
  padding: 2.2rem 1.6rem 0 1.6rem;
  width: 100%;
  height: 92%;
  .title {
    font-size: 18px;
    padding-bottom: 1.8rem;
  }
  ul {
    display: flex;
    align-items: center;
    height: 100%;
    width: 16%;
    padding-left: 0;
    margin-bottom: 0;
    li {
      font-size: 16px;
      width: 50%;
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.active {
        color: @baseColor;
        border-bottom: 3px solid @baseColor;
      }
    }
  }
}
.dFlex {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  height: 50px;
  .search {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .ant-form-item {
      margin: 0 5px;
      align-items: center;
      &:nth-child(1) {
        /deep/.ant-form-item-control .ant-select-selection--single,
        .ant-select-selection__rendered {
          width: 180px !important;
        }
      }
      &:nth-child(2) {
        /deep/.ant-form-item-control .ant-select-selection--single,
        .ant-select-selection__rendered {
          width: 120px !important;
        }
      }
    }
    /deep/.ant-input-group-wrapper {
      width: 240px !important;
    }
  }
}
.ant-form-item-control {
  line-height: auto !important;
}
/deep/.ant-select-selection--single {
  // width: 17rem;
  margin-right: 0.6rem;
  overflow: hidden;
  text-overflow: ellipse;
  white-space: nowrap;
}
/deep/.ant-select {
  margin-top: 15px;
}
</style>
