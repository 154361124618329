<template>
  <div class="index_wrapper">
    <div class="title">商品管理</div>
    <div class="dFlex">
      <ul>
        <li
          :class="path === '/commodity/man' ? 'active' : ''"
          @click="clickTil('/commodity/man')"
        >
          商品列表
        </li>
        <li
          :class="path === '/commodity/warehouseMan' ? 'active' : ''"
          @click="clickTil('/commodity/warehouseMan')"
        >
          库存列表
        </li>
      </ul>
      <div class="search">
        <a-form-item
          label="集团名称"
          style="display: flex; justify-content: flex-end"
        >
          <blocSelect @selectChange="getBlocValue"> </blocSelect>
          <hotel-select v-show="false"></hotel-select>
        </a-form-item>
        <a-form-item
          label="商品分类"
          style="display: flex; justify-content: flex-end"
        >
          <a-select @change="selectClassify" defaultValue="all">
            <a-select-option value="all">全部分类</a-select-option>
            <a-select-option
              v-for="(item, i) in categoryList"
              :key="i"
              :value="item.code"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="商品状态"
          style="display: flex; justify-content: flex-end"
        >
          <a-select @change="selectStatus" defaultValue="all">
            <a-select-option
              v-for="(item, i) in status"
              :key="i"
              :value="item.code"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-input-search
          placeholder="请输入商品名称"
          enter-button="搜索"
          @search="onSearch"
        />
      </div>
    </div>
    <router-view :search="searchList" />
  </div>
</template>

<script>
import { getBlocClassifyStateList } from '../../../api/goods.js'
export default {
  data() {
    return {
      path: '',
      parentId: '',
      blocList: [],
      status: [
        {
          name: '全部',
          code: 'all'
        },
        {
          name: '已下架',
          code: 0
        },
        {
          name: '正常',
          code: 1
        }
      ],
      categoryList: [],
      searchList: {}
    }
  },
  created() {
    this.path = this.$route.path
    this.parentId = this.$store.state.blocValue
    this.searchList = {
      parentId: this.parentId,
      goodsStatus: '',
      categoryId: '',
      goodsName: ''
    }
    this.getClassifyList(this.parentId)
  },
  methods: {
    async getClassifyList(temp) {
      const data = await getBlocClassifyStateList({
        parentId: temp
      })
      this.categoryList.splice(0, this.categoryList.length)
      data.data.forEach(item => {
        this.categoryList.push({
          code: item.id,
          name: item.categoryName
        })
      })
    },
    getBlocValue(value) {
      this.parentId = value
      this.searchList.parentId = value
    },
    // 选择分类
    selectClassify(v) {
      this.searchList.categoryId = v === 'all' ? '' : v
      this.$store.state.category = this.searchList.categoryId
    },
    // 选择状态
    selectStatus(v) {
      if (v === 0) {
        this.searchList.goodsStatus = false
      } else if (v === 1) {
        this.searchList.goodsStatus = true
      } else if (v === 'all') {
        this.searchList.goodsStatus = ''
      }
      this.$store.state.status = this.searchList.goodsStatus
    },
    clickTil(path) {
      this.path = path
      this.$router.push({
        path: path
      })
    },
    // 搜索
    onSearch(value) {
      this.searchList.goodsName = value
    }
  }
}
</script>

<style lang="less" scoped>
@baseColor: #4196ff;
.index_wrapper {
  text-align: left;
  padding: 2.2rem 1.6rem 0 1.6rem;
  width: 100%;
  height: 92%;
  .title {
    font-size: 18px;
    padding-bottom: 1.8rem;
  }
  ul {
    display: flex;
    align-items: center;
    height: 100%;
    width: 16%;
    padding-left: 0;
    margin-bottom: 0;
    li {
      font-size: 16px;
      width: 50%;
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.active {
        color: @baseColor;
        border-bottom: 3px solid @baseColor;
      }
    }
  }
}
.dFlex {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  height: 50px;
  .search {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .ant-form-item {
      margin: 0 5px;
      align-items: center;
      &:nth-child(3) {
        /deep/.ant-form-item-control .ant-select-selection--single,
        .ant-select-selection__rendered {
          width: 100px !important;
        }
      }
      &:nth-child(2) {
        /deep/.ant-form-item-control .ant-select-selection--single,
        .ant-select-selection__rendered {
          width: 120px !important;
        }
      }
    }
    /deep/.ant-input-group-wrapper {
      width: 240px !important;
    }
  }
}
/deep/.ant-select-selection--single {
  // width: 17rem;
  margin-right: 0.6rem;
}
</style>
