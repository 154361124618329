<template>
  <div style="text-align: left">
    <div class="title">选择优惠券</div>
    <div class="content">
      <div class="header">
        <span style="width: 80px; text-align: right">选择卡劵：</span>
        <a-radio-group
          v-model="couponTypeString"
          button-style="solid"
          @change="changeCouponType"
        >
          <a-radio-button value="REDUCE"> 满减劵 </a-radio-button>
          <a-radio-button value="FREE"> 免房劵 </a-radio-button>
        </a-radio-group>
      </div>
      <div class="table">
        <div class="tabTil">
          <span>可选 {{ goodsList.length }} 项</span>
          <div style="display: flex; align-items: center">
            <a-form-item
              label="酒店名称"
              style="
                display: flex;
                justify-content: flex-end;
                margin-right: 8px;
              "
            >
              <hotelSelect @selectChange="getHotelValue"> </hotelSelect>
            </a-form-item>
            <!-- <a-form-item
              label="集团名称"
              style="display: flex; justify-content: flex-end;margin-right:8px"
            >
              <blocSelect @selectChange="getBlocValue"> </blocSelect>
            </a-form-item> -->
            <div>
              <a-input-search
                placeholder="请输入卡劵名称/编码"
                enter-button="搜索"
                @search="onSearch"
              />
            </div>
          </div>
        </div>
        <a-table
          :columns="columns"
          :data-source="goodsList"
          :row-selection="{
            type: 'radio',
            selectedRowKeys: selectedRowKeys,
            onChange: onChange
          }"
          :pagination="false"
          :scroll="{ y: 380 }"
          rowKey="couponCode"
        >
          <span
            slot="startDate"
            slot-scope="text, record"
            style="color: rgba(107, 107, 107, 100)"
          >
            <div style="line-height: 20px !important">
              <span v-if="record.expireTypeString === 'FIXED'"
                >{{ record.startDate | formatDate1 }}-{{
                  record.endDate | formatDate1
                }}
              </span>
              <span v-if="record.expireTypeString === 'MOVE'">
                领取后{{ record.validityDay }}天内可用
              </span>
              <div>
                不可用时间:
                <span v-if="record.unavailableDays !== ''">
                  {{ record.unavailableDays | strCarr }}
                </span>
                <span v-if="record.unavailablePeriod">
                  {{ record.unavailablePeriod | getUnTime }}
                </span>
              </div>
            </div>
          </span>
          <span slot="orderTypeString" slot-scope="text">
            {{ text | orderType }}
          </span>
          <span slot="hotelId" slot-scope="text">
            {{ getName(text) }}
          </span>
          <span slot="createUserName" slot-scope="text, record">
            {{ record.createUserName }} / {{ record.createDeptName }}
          </span>
        </a-table>
        <a-form-model-item
          :wrapper-col="{ span: 14, offset: 10 }"
          style="padding: 20px 0"
        >
          <a-button style="margin-right: 1rem" @click="back"> 取消 </a-button>
          <a-button type="primary" @click="onSubmit"> 确认 </a-button>
        </a-form-model-item>
      </div>
    </div>
  </div>
</template>

<script>
import { querythirdCoupons } from '../../../../api/goods.js'
const columns = [
  {
    title: '卡劵名称',
    dataIndex: 'couponShowName'
  },
  {
    title: '卡劵编码',
    dataIndex: 'couponCode'
  },
  {
    title: '可售数量',
    width: 100,
    dataIndex: 'availableAmount'
  },
  {
    title: '有效期',
    dataIndex: 'startDate',
    scopedSlots: { customRender: 'startDate' }
  },
  {
    title: '适用订单',
    dataIndex: 'orderTypeString',
    scopedSlots: { customRender: 'orderTypeString' }
  },
  {
    title: '创建人及部门',
    dataIndex: 'createUserName',
    scopedSlots: { customRender: 'createUserName' }
  },
  {
    title: '适用门店',
    dataIndex: 'hotelId',
    scopedSlots: { customRender: 'hotelId' }
  }
]
export default {
  data() {
    return {
      goodsList: [],
      columns,
      couponTypeString: 'REDUCE',
      selectedRowKeys: [],
      selectedRows: [],
      params: {},
      searchKey: '',
      blocValue: '',
      hotelName: '',
      hotelValue: '',
      browse: true
    }
  },
  created() {
    this.blocValue = this.$store.state.blocValue
    this.hotelValue = this.$store.state.hotelValue
    this.isInserts = this.$route.params.isInserts
    this.params = {
      hotelId: this.hotelValue,
      tenantId: this.blocValue,
      couponTypeString: this.couponTypeString
    }
    if (this.$route.params.info) {
      this.selectedRowKeys.push(this.$route.params.info.couponCode)
      this.params.couponTypeString = this.$route.params.info.couponTypeString
    }
    this.fn(this.params)
  },
  methods: {
    async fn(obj) {
      const data = await querythirdCoupons(obj)
      this.goodsList = data.data
    },
    getName(v) {
      if (v !== -1) {
        const hotelName = this.$store.state.hotelArray.filter(item => {
          return Number(item.hotelId) === v
        })
        this.hotelName = hotelName[0].name
      } else {
        this.hotelName = '所有门店'
      }
      return this.hotelName
    },
    onChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    changeCouponType(v) {
      if (v.target.value === 'REDUCE') {
        this.params.couponTypeString = 'REDUCE'
      } else {
        this.params.couponTypeString = 'FREE'
      }
      this.fn(this.params)
    },
    back() {
      if (this.isInserts) {
        this.$router.push({
          name: 'insert',
          params: {
            browse: this.browse
          }
        })
      } else {
        this.$router.push({
          name: 'edit',
          params: {
            browse: this.browse
          }
        })
      }
    },
    onSubmit() {
      if (this.isInserts) {
        this.$router.push({
          name: 'insert',
          params: {
            conpon: this.selectedRows,
            browse: this.browse
          }
        })
      } else {
        this.$router.push({
          name: 'edit',
          params: {
            conpon: this.selectedRows,
            browse: this.browse
          }
        })
      }
    },
    onSearch(v) {
      this.searchKey = v
      this.params.searchKey = v
      this.fn(this.params)
    },
    getHotelValue(value) {
      this.params.hotelId = value
      this.fn(this.params)
    },
    getBlocValue(value) {
      this.params.tenantId = value
      this.fn(this.params)
    }
  },
  filters: {
    orderType: function(v) {
      let str = ''
      switch (v) {
        case 'FULL':
          str = '全日房'
          break
        case 'HOURLY':
          str = '时租房'
          break
        case 'ALL':
          str = '全部房型'
          break
      }
      return str
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  margin: 16px 0 16px 20px;
  font-size: 16px;
  color: #101010;
}
.content {
  background-color: #fff;
  padding: 16px 20px;
  .header {
    display: flex;
    align-items: center;
  }
  .table {
    margin: 20px 0 0 0px;
    border: 1px solid #ccc;
    border-radius: 4px;
    /deep/.ant-form-item {
      margin-bottom: 0 !important;
    }
    .tabTil {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 9px 14px 10px 16px;
    }
  }
}
</style>
