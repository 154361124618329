import req from '../utils/request.js'
const path = '/api/mall'

/* 获取商品列表 */
export function getBlocGoodsList(params) {
  return req.post(`${path}/manage/goods/list`, params)
}

/* 商品分类列表状态 */
export function getBlocClassifyStateList(params) {
  return req.fromPost(`${path}/manage/category/list`, params)
}

/* 新增商品 */
export function insertBlocGoods(params, headers) {
  return req.fromAutoHeader(`${path}/manage/goods/insert`, params, headers)
}

/* 获取可绑定的虚拟商品 */
export function querythirdCoupons(params) {
  return req.post('/api/marketing/coupon/third/querythirdCoupons', params)
}
// 获取卡劵详情/getCouponInfo
export function getCouponInfo(params) {
  return req.fromPost('/api/marketing/coupon/third/getCouponInfo', params)
}

/* 修改商品 */
export function updateBlocGoods(params, headers) {
  return req.fromAutoHeader(`${path}/manage/goods/update`, params, headers)
}

/* 改变商品状态 */
export function changeBlocGoodsStatus(params) {
  return req.fromPost(`${path}/manage/goods/status`, params)
}

/* 获取库存日志列表 */
export function getBlocStockLogList(params) {
  return req.post(`${path}/manage/stock/logList`, params)
}

/* 修改库存数量 */
export function updateBlocStockLList(params) {
  return req.post(`${path}/manage/stock/change`, params)
}
