import req from '../utils/request.js'
const path = '/api/mall'

/* 获取流水列表 */
export function getMallFlow(params) {
  return req.post(`${path}/manage/flow/list`, params)
}

/* 核销操作 */
export function changeFlowVerify(params) {
  return req.post(`${path}/manage/flow/verify`, params)
}

/* 编辑备注操作 */
export function editRemark(params) {
  return req.fromPost(`${path}/manage/flow/editRemark`, params)
}
