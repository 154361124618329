<template>
  <div style="height:92%">
    <router-view />
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped></style>
