<template>
  <div class="index_wrapper">
    <div class="title">门店商城流水</div>
    <div
      style="
        display: flex;
        justify-content: space-between;
        padding: 1rem 1.5rem;
      "
    >
      <a-button type="primary" @click="flowVerifyModel"> 批量核销 </a-button>
      <a-modal v-model="flowVerifyVis" title="批量核销" @ok="moreFlowVerify">
        <a-table
          bordered
          :data-source="flowList"
          :pagination="false"
          rowKey="id"
          style="
            width: 100%;
            height: 300px;
            max-height: 300px;
            overflow-y: auto;
          "
        >
          <a-table-column key="orderNo" title="订单号" data-index="orderNo" />
          <a-table-column key="userName" title="姓名" data-index="userName" />
          <a-table-column key="price" title="订单金额" data-index="price">
            <template slot-scope="record">
              {{ record ? record.toFixed(2) : 0 }}
            </template>
          </a-table-column>
          <a-table-column
            key="collectTypeString"
            title="收款方式"
            data-index="collectTypeString"
          >
            <template slot-scope="record">
              {{ record === 'BALANCE' ? '余额支付' : '微信支付' }}
            </template>
          </a-table-column>
        </a-table>
        <div style="font-size: 14px; margin-top: 5px">
          核销总金额：<span class="cd75"
            >{{ flowAomunt ? flowAomunt.toFixed(2) : 0 }}元</span
          >
          <span class="cd75" style="margin-left: 26px">
            请仔细核对并确认已收到的金额
          </span>
        </div>
      </a-modal>
      <span>
        合计金额
        <span class="cd75">{{ amount ? amount.toFixed(2) : 0 }}元</span>
      </span>
    </div>
    <div class="content">
      <div class="search">
        <a-form-item
          label="状态"
          style="display: flex; justify-content: flex-end"
        >
          <a-select
            @change="selectStatus"
            :defaultValue="flowStatus ? '1' : '0'"
          >
            <a-select-option value="1"> 已核销 </a-select-option>
            <a-select-option value="0"> 未核销 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="订单状态"
          style="display: flex; justify-content: flex-end"
        >
          <a-select
            @change="selectOrderStatus"
            :defaultValue="orderStatusString === '' ? 'all' : orderStatusString"
          >
            <a-select-option value="all"> 全部 </a-select-option>
            <a-select-option value="WAITING_DELIVERY"> 待收货 </a-select-option>
            <a-select-option value="RECEIVED"> 已收货 </a-select-option>
            <a-select-option value="DONE"> 已完成 </a-select-option>
            <a-select-option value="WAITING_REFUND"> 待退款 </a-select-option>
            <a-select-option value="REFUNDED"> 已退款 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="酒店名称"
          style="display: flex; justify-content: flex-end"
        >
          <hotelSelect @selectChange="getHotelValue"></hotelSelect>
        </a-form-item>
        <a-form-item
          label="下单时间"
          style="display: flex; justify-content: flex-end"
        >
          <a-range-picker @change="onDateChange" />
        </a-form-item>
        <a-input-search
          placeholder="会员名、会员号、手机号"
          enter-button="搜索"
          @search="onSearch"
        />
        <a-button type="primary" style="margin-left: 5px" @click="exportData">
          导出
        </a-button>
      </div>
      <a-table
        bordered
        :data-source="flowData"
        :loading="loading"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onChange
        }"
        :pagination="false"
        rowKey="id"
      >
        <a-table-column key="orderNo" title="订单号" data-index="orderNo">
          <template slot-scope="text, record">
            <a href="javascript:;" @click="toOrderList(record)">
              {{ record.orderNo }}
            </a>
          </template>
        </a-table-column>
        <a-table-column
          key="mchTransactionId"
          title="商户订单号"
          data-index="mchTransactionId"
        />
        <a-table-column key="mchId" title="交易商户号" data-index="mchId" />
        <a-table-column key="userName" title="姓名" data-index="userName" />
        <a-table-column
          key="orderStatusString"
          title="订单状态"
          data-index="orderStatusString"
        >
          <template slot-scope="record">
            <span>
              {{ record | orderStatus }}
            </span>
          </template>
        </a-table-column>
        <a-table-column key="price" title="订单金额" data-index="price">
          <template slot-scope="record">
            <span class="cd75">
              {{
                orderStatusString === 'REFUNDED'
                  ? -record.toFixed(2)
                  : record.toFixed(2)
              }}元
            </span>
          </template>
        </a-table-column>
        <a-table-column
          key="collectAmount"
          title="流水金额"
          data-index="collectAmount"
        >
          <template slot-scope="record">
            {{ record ? record.toFixed(2) : 0 }}元
          </template> </a-table-column
        >Î
        <a-table-column
          key="collectTypeString"
          title="收款方式"
          data-index="collectTypeString"
        >
          <template slot-scope="record">
            <span>
              {{ record === 'BALANCE' ? '余额支付' : '微信支付' }}
            </span>
          </template>
        </a-table-column>

        <a-table-column
          key="collectTime"
          title="交易时间"
          data-index="collectTime"
        >
          <template slot-scope="record">
            {{ record | formatDate }}
          </template>
        </a-table-column>
        <a-table-column key="remark" title="备注" data-index="remark" />
        <a-table-column key="status" title="状态" data-index="status">
          <template slot-scope="record">
            <span>
              {{ record ? '已核销' : '未核销' }}
            </span>
          </template>
        </a-table-column>
        <a-table-column key="action" title="操作">
          <template slot-scope="record">
            <span>
              <a @click="sureVerify(record)" v-if="!flowStatus">核销</a>
              <a-divider type="vertical" v-if="!flowStatus" />
              <a @click="editFlow(record)">编辑</a>
            </span>
          </template>
        </a-table-column>
      </a-table>
      <a-modal v-model="editVerifyVis" title="编辑" @ok="flowVerify">
        <a-form-model
          :model="editFlowVerifyData"
          ref="ruleForm"
          :rules="storageRules"
        >
          <a-form-model-item
            ref="remark"
            label="备注"
            style="display: flex"
            prop="remark"
          >
            <a-input
              v-model="editFlowVerifyData.remark"
              ref="descInputValue"
              type="textarea"
              @keyup="descpNum"
            />
            <span class="descNum">{{ descNum }}/50</span>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <!-- 分页 -->
      <a-pagination
        show-quick-jumper
        :page-size-options="pageSizeOptions"
        :showSizeChanger="true"
        :current="page"
        :total="total"
        :show-total="(total) => `共 ${total} 条数据`"
        :pageSize="limit"
        @change="onChangePage"
        @showSizeChange="onShowSizeChange"
      />
    </div>
  </div>
</template>

<script>
import { getMallFlow, changeFlowVerify, editRemark } from '../../api/flow.js'
import moment from 'moment'
import XLSX from 'xlsx'
// let flowId = []
// let flowList = []
// const rowSelection = {
//   onChange: (selectedRowKeys, selectedRows) => {
//     flowId = selectedRowKeys
//     flowList = selectedRows
//   },
//   onSelect: (record, selected, selectedRows) => {},
//   onSelectAll: (selected, selectedRows, changeRows) => {}
// }
export default {
  inject: ['reload'],
  data() {
    return {
      storageRules: {
        remark: [
          {
            required: true,
            min: 0,
            max: 50,
            message: '长度限制50个字符',
            trigger: 'blur'
          }
        ]
      },
      value: '',
      hotelList: [],
      flowData: [],
      searchKey: '',
      // 核销状态
      flowStatus: false,
      // 订单状态
      orderStatusString: '',
      startTime: '',
      endTime: '',
      indeterminate: true,
      checkAll: false,
      // 核销
      flowVerifyVis: false,
      flowId: '',
      flowList: '',
      flowAomunt: 0,
      editVerifyVis: false,
      editFlowVerifyData: {},
      // 总金额
      amount: 0,
      // 分页参数
      pageSizeOptions: ['5', '10', '20', '30', '50'],
      page: 1,
      limit: 10,
      total: 20,
      descNum: 0,
      params: {},
      selectedRowKeys: [],
      selectedRows: [],
      isEnter: true,
      loading: true
    }
  },
  created() {
    this.isEnter = true
    this.hotelList = this.$store.state.hotelArray
    this.parentId = this.$store.state.hotelValue
    this.params = {
      limit: this.limit,
      page: this.page,
      parentId: this.parentId,
      flowStatus: this.flowStatus,
      orderStatusString: this.orderStatusString,
      buyTypeString: 'CASH'
    }
    setTimeout(() => {
      this.getList(this.params)
    }, 500)
  },
  methods: {
    onChange(selectedRowKeys, selectedRows) {
      this.flowId = selectedRowKeys
      this.flowList = selectedRows
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    async getList(params) {
      this.isEnter = false
      this.loading = true
      this.amount = 0
      const data = await getMallFlow(params)
      this.loading = false
      this.amount = data.data.totalAmount
      this.flowData = data.data.page.records
      this.total = data.data.page.total
    },
    getHotelValue(v) {
      if (this.isEnter) {
        return
      }
      this.params.parentId = v
      this.getList(this.params)
    },
    // 核销
    flowVerifyModel() {
      if (this.flowId.length !== 0 && !this.flowStatus) {
        this.flowVerifyVis = true
        let price = 0
        this.flowList.forEach(item => {
          price += item.price
        })
        this.flowAomunt = price
      } else {
        return false
      }
    },
    // 批量核销
    async moreFlowVerify() {
      const flowId = this.flowId
      const data = await changeFlowVerify(flowId)
      if (data.code === 0) {
        this.reload()
        this.$message.success(data.message)
      } else {
        this.$message.error(data.message)
      }
    },
    // 单个核销
    sureVerify(r) {
      const _this = this
      this.$confirm({
        title: '确认提示',
        content: '请仔细核对并确认已收到的金额',
        okType: 'danger',
        async onOk() {
          const data = await changeFlowVerify([r.id])
          if (data.code === 0) {
            _this.$message.success(data.message)
            _this.reload()
          } else {
            _this.$message.error(data.message)
          }
        }
      })
    },
    // 编辑核销
    editFlow(r) {
      this.editVerifyVis = true
      this.editFlowVerifyData = JSON.parse(JSON.stringify(r))
      this.descNum = this.editFlowVerifyData.remark
        ? this.editFlowVerifyData.remark.length
        : 0
    },
    // 编辑
    async flowVerify() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const data = await editRemark({
            flowId: this.editFlowVerifyData.id,
            remark: this.editFlowVerifyData.remark
          })
          if (data.code === 0) {
            this.reload()
            this.$message.success(data.message)
          } else {
            this.$message.error(data.message)
          }
        }
      })
    },
    // 导出
    transData(columns, tableList) {
      const tableHead = columns.map(item => item.title)
      const tableBody = tableList.map(item => [
        item.orderNo,
        item.userName,
        item.orderStatusString,
        item.price,
        item.collectTypeString,
        item.mchTransactionId,
        item.mchId,
        item.collectTime,
        item.remark,
        item.status
      ])
      return [tableHead, ...tableBody]
    },
    async exportData() {
      const data = await getMallFlow({
        limit: 1000,
        page: 1,
        parentId: this.params.parentId,
        flowStatus: this.flowStatus,
        orderStatusString: this.params.orderStatusString,
        startTime: this.startTime ? this.startTime : '',
        endTime: this.endTime ? this.endTime : '',
        searchKey: this.searchKey
      })

      this.flowData = data.data.page.records
      this.total = data.data.page.total
      const columns = [
        { title: '订单号', dataIndex: 'orderNo' },
        { title: '姓名', dataIndex: 'userName' },
        { title: '订单状态', dataIndex: 'orderStatusString' },
        { title: '订单金额', dataIndex: 'price' },
        { title: '收款方式', dataIndex: 'collectTypeString' },
        { title: '商户订单号', dataIndex: 'mchTransactionId' },
        { title: '交易商户号', dataIndex: 'mchId' },
        { title: '收款时间', dataIndex: 'collectTime' },
        { title: '备注', dataIndex: 'remark' },
        { title: '状态', dataIndex: 'status' }
      ]
      const tableList = []
      console.log(data.data.records)
      this.flowData.forEach(item => {
        tableList.push({
          orderNo: item.orderNo,
          userName: item.userName,
          orderStatusString: this.$options.filters.orderStatus(
            item.orderStatusString
          ),
          price: item.price,
          collectTypeString:
            item.collectTypeString === 'BALANCE' ? '余额支付' : '微信支付',
          mchTransactionId: item.mchTransactionId,
          mchId: item.mchId,
          collectTime: this.$options.filters.formatDate(item.collectTime),
          remark: item.remark,
          status: item.status ? '已核销' : '未核销'
        })
      })
      const tableData = this.transData(columns, tableList)
      // 将一组 JS 数据数组转换为工作表
      const ws = XLSX.utils.aoa_to_sheet(tableData)
      // 创建 workbook
      const wb = XLSX.utils.book_new()
      // 将 工作表 添加到 workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      // 将 workbook 写入文件
      XLSX.writeFile(wb, '商城流水.xlsx')
    },
    toOrderList(o) {
      this.$router.push({
        path: 'storeShop/order/man',
        query: { orderData: o }
      })
    },
    descpNum() {
      this.descNum = this.$refs.descInputValue.value
        ? this.$refs.descInputValue.value.length
        : 0
    },
    // 选择核销状态
    selectStatus(v) {
      if (v === '1') {
        this.params.flowStatus = true
      } else {
        this.params.flowStatus = false
      }
      this.flowStatus = this.params.flowStatus
      this.getList(this.params)
    },
    // 选择订单状态
    async selectOrderStatus(v) {
      if (v === 'all') {
        this.params.orderStatusString = ''
      } else {
        this.params.orderStatusString = v
      }
      this.getList(this.params)
    },
    // 分页
    async onChangePage(pageNumber) {
      this.page = pageNumber
      this.params.page = pageNumber
      this.flowList = []
      this.flowId = []
      this.selectedRowKeys = []
      this.selectedRows = []
      this.getList(this.params)
    },
    onShowSizeChange(pageNumber, limit) {
      this.page = 1
      this.params.limit = limit
      this.getList(this.params)
    },
    // 日期
    async onDateChange(date, dateString) {
      this.startTime = moment(dateString[0]).format('YYYY-MM-DD')
      this.endTime = moment(dateString[1]).format('YYYY-MM-DD')
      this.startTime = new Date(this.startTime).getTime()
      this.endTime =
        new Date(this.endTime).getTime() + (24 * 60 * 60 * 1000 - 1)

      this.params.endTime = this.endTime
      this.params.startTime = this.startTime
      this.getList(this.params)
    },
    // 搜索
    async onSearch(value) {
      this.searchKey = value
      this.params.searchKey = value
      this.getList(this.params)
    }
  },
  computed: {
    //  定义表头数据
    titleArr() {
      const arr = []
      this.columns.map(item => {
        arr.push(item.title)
      })
      return arr
    }
  },
  filters: {
    formatDate: function(value) {
      let date = new Date(value)
      date = moment(date).format('YYYY-MM-DD HH:mm:ss')
      return date
    },
    orderStatus(v) {
      switch (v) {
        case 'WAITING_PAY':
          return '待支付'
        case 'WAITING_DELIVERY':
          return '待收货'
        case 'RECEIVED':
          return '已收货'
        case 'DONE':
          return '已完成'
        case 'CANCELED':
          return '已取消'
        case 'WAITING_REFUND':
          return '待退款'
        case 'REFUNDED':
          return '已退款'
        default:
          return '异常'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.index_wrapper {
  text-align: left;
  padding: 2.2rem 1.6rem 0 1.6rem;
  width: 100%;
  .cd75 {
    color: #d75678;
  }
  .title {
    font-size: 18px;
  }
  .content {
    background-color: #fff;
    padding-bottom: 2.4rem;
    table {
      background-color: #fff;
    }
    .search {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 5rem;
      line-height: 5rem;
      .ant-form-item {
        margin: 0 5px;
        /deep/.ant-form-item-control .ant-select-selection--single,
        .ant-select-selection__rendered {
          width: 100px !important;
        }
        &:nth-child(3) {
          /deep/.ant-form-item-control .ant-select-selection--single,
          .ant-select-selection__rendered {
            width: 220px !important;
          }
        }
        /deep/.ant-calendar-picker {
          width: 220px;
        }
      }
      /deep/.ant-input-group-wrapper {
        width: 240px !important;
      }
    }
  }
}
/deep/.ant-modal {
  width: 600px !important;
  .ant-form-item-control-wrapper {
    width: 90%;
  }
}
</style>
