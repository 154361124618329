<template>
  <div class="index_wrapper">
    <div class="title">商城授权</div>
    <div class="content">
      <div style="display: flex; justify-content: flex-end; padding: 1.6rem 0">
        <a-form-item label="酒店名称" style="display: flex">
          <hotelSelect @selectChange="getHotelValue"> </hotelSelect>
        </a-form-item>
      </div>
      <a-table bordered :data-source="data" :pagination="false" rowKey="id" :loading="loading">
        <a-table-column
          key="parentName"
          title="酒店名称"
          data-index="parentName"
        />
        <a-table-column
          key="expireTime"
          title="到期时间"
          data-index="expireTime"
        >
          <template slot-scope="record">
            <span>
              {{ record | formatDate }}
            </span>
          </template>
        </a-table-column>
        <a-table-column key="remark" title="备注" data-index="remark" />
        <a-table-column key="status" title="商城授权状态">
          <template slot-scope="record">
            <span>
              <a-switch :checked="record.status" @click="onChange(record)" />
            </span>
          </template>
        </a-table-column>
        <a-table-column key="action" title="操作">
          <template slot-scope="record">
            <span>
              <a @click="edit(record)">编辑</a>
            </span>
          </template>
        </a-table-column>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        show-quick-jumper
        :current="page"
        :total="total"
        :show-total="(total) => `共 ${total} 条数据`"
        :pageSize="limit"
        @change="onChangePage"
      />

      <!-- 编辑 -->
      <a-modal
        v-model="visible"
        title="编辑"
        okText="确定"
        cancelText="取消"
        @ok="onSubmit"
        destroyOnClose
      >
        <a-form-model :model="editData" ref="ruleForm" :rules="rules">
          <div class="form_model">
            <a-form-model-item label="集团名称">
              <span>{{ editData.parentName }}</span>
            </a-form-model-item>
            <a-form-model-item label="到期时间" prop="expireTime">
              <a-date-picker
                format="YYYY-MM-DD HH:mm:ss"
                :disabledDate="disabledDate"
                :defaultValue="
                  moment(editData.expireTime, 'YYYY-MM-DD HH:mm:ss')
                "
                :show-time="{
                  defaultValue: moment('00:00:00', 'HH:mm:ss')
                }"
                @change="checkDate"
              />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark">
              <a-input
                v-model="editData.remark"
                type="textarea"
                ref="descInputValue"
                @keyup="descpNum"
              />
              <span class="descNum">{{ descNum }}/200</span>
            </a-form-model-item>
          </div>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  getBlocList,
  insertBlocList,
  updateBlocList,
  updateBlocListState
} from '../../api/blocImpower.js'
export default {
  inject: ['reload'],
  data() {
    this.dateFormat = 'YYYY-MM-DD'
    return {
      value: 'all',
      hotelList: [],
      data: [],
      Id: [],
      // 新增
      insertVisible: false,
      insertData: {
        blocValue: '',
        hotelValue: ''
      },
      // 编辑数据对象
      editData: {},
      rules: {
        blocValue: [
          {
            required: true,
            message: '请选择集团！',
            trigger: 'blur'
          }
        ],
        hotelValue: [
          {
            required: true,
            message: '请选择酒店！',
            trigger: 'blur'
          }
        ],
        expireTime: [
          {
            required: true,
            message: '请选择到期时间！',
            trigger: 'change'
          }
        ],
        remark: [
          {
            min: 0,
            max: 200,
            message: '长度限制200个字符！',
            trigger: 'blur'
          }
        ]
      },
      title: '',
      visible: false,
      // 文本域动态长度
      descNum: 0,
      // 分页参数
      page: 1,
      limit: 10,
      total: 10,
      tempParentId: [],
      params: {},
      isEnter: true,
      loading: true
    }
  },
  created() {
    this.isEnter = true
    setTimeout(() => {
      this.hotelList = []
      this.$store.state.hotelArray.forEach(item => {
        this.hotelList.push(item.hotelId)
      })
      this.params = {
        parentIdList: this.$store.state.hotelValue
          ? [this.$store.state.hotelValue]
          : this.hotelList,
        limit: this.limit,
        page: this.page,
        mallTypeString: 'HOTEL'
      }
      this.getList(this.params)
    }, 500)
  },
  methods: {
    // 获取集团授权列表
    async getList(obj) {
      this.isEnter = false
      this.loading = true
      const data = await getBlocList(obj)
      this.loading = false
      if (data.code === 0) {
        this.data = data.data.records
        this.total = data.data.total
      }
    },
    getHotelValue(value, e) {
      if (this.isEnter) {
        return
      }
      this.params.parentIdList = []
      this.params.parentIdList.push(value)
      this.getList(this.params)
    },
    moment,
    checkDate(value) {
      this.editData.expireTime = value
    },
    disabledDate(current) {
      return current && current < moment().endOf('day')
    },
    // 分页
    onChangePage(pageNumber) {
      this.page = pageNumber
      this.params.page = pageNumber
      this.getList(this.params)
    },
    blocSelect(v) {
      this.insertData.blocValue = v
    },
    hotelSelect(v) {
      this.insertData.hotelValue = v
    },
    // 修改状态
    onChange(r) {
      const self = this
      this.$confirm({
        title: '确认提示',
        content: '是否确认商城修改状态？',
        okType: 'danger',
        async onOk() {
          const data = await updateBlocListState({
            mallId: r.id,
            status: !r.status
          })
          if (data.code === 0) {
            self.params.page = 1
            self.getList(self.params)
            self.$message.success(data.message)
            self.visible = false
          } else {
            self.$message.error(data.message)
          }
        }
      })
    },
    // 备注文字数量计算
    descpNum() {
      this.descNum =
        this.$refs.descInputValue.value === null
          ? 0
          : this.$refs.descInputValue.value.length
    },
    insertBtn() {
      this.insertVisible = true
      this.insertData.parentId = this.parentList1[0].code
      this.insertData.parentName = this.parentList1[0].name
    },
    // 编辑
    edit(r) {
      this.title = '编辑'
      this.visible = true
      r.expireTime = this.$options.filters.formatDate(r.expireTime)
      this.editData = JSON.parse(JSON.stringify(r))
      this.descNum = r.remark !== null ? r.remark.toString().length : 0
    },
    // 编辑
    onSubmit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.editData.expireTime = new Date(
            this.editData.expireTime
          ).getTime()
          const data = await updateBlocList(this.editData)
          if (data.code === 0) {
            this.visible = false
            this.$message.success(data.message)
            this.reload()
          } else {
            this.$message.error(data.message)
          }
        } else {
          return false
        }
      })
    }
  },
  computed: {
    getHotelId() {
      return this.$store.state.shopIdArr
    }
  },
  watch: {
    getHotelId: {
      handler: function() {
        const Id = this.$store.state.shopIdArr
        if (Id[0] === 'all') {
          const temp = []
          this.parentList.forEach(item => {
            item.code !== 'all' ? temp.push(item.code) : ''
          })
          this.getList(temp, this.limit, this.page)
        } else {
          this.getList(Id, 1, 1)
        }
      }
    }
  },
  // 时间戳转时间
  filters: {
    formatDate: function(value) {
      let date = new Date(value)
      date = moment(date).format('YYYY-MM-DD HH:mm:ss')
      return date
    }
  }
}
</script>

<style lang="less" scoped>
@baseColor: #4196ff;
.index_wrapper {
  text-align: left;
  padding: 2.2rem 1.6rem 0 1.6rem;
  width: 100%;
  height: 90%;
  .title {
    font-size: 18px;
    padding-bottom: 18px;
  }
  .content {
    background-color: #fff;
    padding-bottom: 2.4rem;
    height: 100%;
    button {
      margin-left: 0.8rem;
    }
    .ant-form-item {
      margin: 0;
    }
    .ant-select {
      height: 32px;
      padding-right: 1rem;
    }
  }
}
.half {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .ant-form-item {
    width: 50%;
    /deep/.ant-select-selection--single {
      width: 200px;
    }
  }
}
/deep/.ant-modal {
  width: 700px !important;
}
.form_model .ant-form-item .ant-form-item-label {
  width: 90px;
}
/deep/.ant-modal-content {
  .ant-calendar-picker {
    width: 200px;
  }
  /deep/.ant-btn-primary {
    background-color: @baseColor;
    border-color: @baseColor;
    &:hover {
      color: #fff !important;
    }
  }
  /deep/ .ant-btn:hover {
    color: @baseColor;
    border-color: @baseColor;
  }
}
</style>
