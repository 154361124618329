<template>
  <div class="about">
    <h1>路径跳转失败，将在{{ time }}秒后跳转会首页</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {
      time: 5
    }
  },
  created() {
    const timeId = setInterval(() => {
      this.time--
      if (this.time === 0) {
        clearInterval(timeId)
        this.$router.push({
          path: '/index'
        })
      }
    }, 1000)
  }
}
</script>

<style lang="less">
h1 {
  margin-left: 20px;
}
</style>
